import _ from 'lodash';
import axios from 'axios';
// import moment from 'moment';
import moment from 'moment-timezone';
import Vue from 'vue';
import VueImg from 'v-img';
import vSelect from 'vue-select';
import Antd from 'ant-design-vue';
import { VuejsDatatableFactory } from 'vuejs-datatable';
import Paginate from 'vuejs-paginate';
import vuePusher from 'vue-pusher';
// import tinymce from 'vue-tinymce-editor';
// import vuexI18n from 'vuex-i18n';
// import VueI18n from 'vue-i18n';

import App from './App';
import router from './router';
import store from './store';
import './styles/style.scss';
import '@/main.scss';
import 'ant-design-vue/dist/antd.css';
import isMobileMixin from './mixins/isMobile';
import langMixin from './mixins/language';
import formValidator from './mixins/validator';
import methodsMixins from './mixins/methods';
import isMobileDetect from './utils/isMobileDetect';

const updateIsMobileInStore = () => {
  setTimeout(() => {
    const isMobile = isMobileDetect();
    if (isMobile !== store.state.isMobile) {
      store.dispatch('mobileDetector', {
        isMobile,
      });
    }
    if (isMobile) {
      document.getElementsByTagName('body')[0]
        .classList
        .add('is-mobile');
    } else {
      document.getElementsByTagName('body')[0]
        .classList
        .remove('is-mobile');
    }
  }, 0);
};
updateIsMobileInStore();
window.addEventListener('resize', updateIsMobileInStore);
const setLanguage = () => {
  const localeLang = localStorage.getItem('lang');
  if (localeLang) {
    store.dispatch('setLanguage', {
      lang: localeLang,
    });
  }
};
function checkLoginStatus() {
  if (!localStorage.getItem('token') && window.location.pathname !== '/login') {
    window.open('/login');
    window.location.reload();
  }
}
document.addEventListener('visibilitychange', checkLoginStatus);
setLanguage();
Vue.config.productionTip = false;

function setGlobalTimeZone() {
  const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const oldSavedTimeZone = localStorage.getItem('siteSelectedTimeZone');
  if (!oldSavedTimeZone) {
    localStorage.setItem('siteSelectedTimeZone', localTimeZone);
  }
  return oldSavedTimeZone || localTimeZone;
}
// window.moment = moment;
window.moment = moment.tz.setDefault(setGlobalTimeZone());

window._ = _;
window.axios = axios;

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.timeout = 500000;
const refreshTokenAndRender = async () => {
  const token = localStorage.getItem('token');

  if (token) {
    axios.defaults.headers = {
      Authorization: `Bearer ${token}`,
    };
    await store.dispatch('auth/refreshToken');
  }
  // let counter = 0;
  // axios.interceptors.request.use((request) => {
  //   console.log(request);
  //   // eslint-disable-next-line no-unused-vars
  //   counter++;
  //   console.log(counter);
  //   request.eject();
  // });
  axios.interceptors.response.use((response) => {
    // eslint-disable-next-line no-param-reassign
    response.data = response.data.payload;
    return response;
  }, (error) => {
    if (error.response && error.response.status === 400) {
      store.dispatch('refreshToken');
    }
    return Promise.reject(error);
  });
  // Vue.use(vuexI18n.plugin, store);
  // const i18n = new VueI18n({
  //   locale: 'en',
  // });
  // Vue.use(VueI18n);
  // Vue.i18n.add('en', {});
  // Vue.i18n.add('ru', {});
  // Vue.i18n.set('en');
  // Vue.use(VueI18n);
  Vue.mixin(isMobileMixin);
  Vue.use(VueImg);
  Vue.mixin(langMixin);
  Vue.mixin(formValidator);
  Vue.mixin(methodsMixins);
  Vue.use(VuejsDatatableFactory);
  // Vue.component('Tinymce', tinymce);
  // window.tinymce = tinymce;
  Vue.use(Antd);
  Vue.component('VSelect', vSelect);
  Vue.component('Paginate', Paginate);
  if (process.env.VUE_APP_PUSHER_API_KEY) {
    Vue.use(vuePusher, {
      api_key: process.env.VUE_APP_PUSHER_API_KEY,
      options: {
        cluster: 'ap2',
        encrypted: true,
      },
    });
  }

  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount('#app');
};

refreshTokenAndRender();
